import { Button, HStack, Stack, VStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { SectionTitle } from '$/pages/EditorPage/components/AttributesSection/components/SectionTitle';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import { EditorNumberInput } from '$/pages/EditorPage/components/AttributesSection/NumberInput';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const PathSidebar = () => {
  const { t } = useTranslation();

  const layerIndex = useEditorStore.useActiveLayerIndex();
  const componentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const { setPathPointPosition, setLayerEditMode, enableSmoothLayer } =
    useEditorActions();

  if (componentIndex == null || layerIndex == null) return;

  const layer = components[componentIndex].layers[layerIndex];

  if (layer == null) return;

  return (
    <Stack w='full' pb='4'>
      <TitleSection title={t('editor.brush.title')} />
      <SidebarDivider />
      <CurrentColorSection />
      {layer.isClosed && (
        <>
          <VisualizationSection />
          <SidebarDivider />
        </>
      )}
      <HStack justify='space-between' px='4'>
        <SectionTitle title={t('editor.path.pathPoints')} icon='area' />
        <Text color='lighterText' fontSize='xs'>
          {layer.cnt}
        </Text>
      </HStack>
      <SidebarDivider />
      {layer.isClosed && (
        <>
          <VStack alignItems='flex-start' w='full' px='4'>
            <SectionTitle
              title={t('editor.path.selectionMode')}
              icon='pencil'
            />
            <HStack justify='stretch' w='full'>
              <Button
                w='full'
                h='8'
                isActive={layer.editMode === 'select'}
                onClick={() =>
                  setLayerEditMode(componentIndex, layerIndex, 'select')
                }
                variant='tertiary'
              >
                <Icon boxSize='16px' icon='pencil' />
              </Button>
              <Button
                w='full'
                h='8'
                isActive={layer.editMode === 'add'}
                onClick={() =>
                  setLayerEditMode(componentIndex, layerIndex, 'add')
                }
                variant='tertiary'
              >
                <Icon boxSize='16px' icon='pencil_add' />
              </Button>
              <Button
                w='full'
                h='8'
                isActive={layer.editMode === 'delete'}
                onClick={() =>
                  setLayerEditMode(componentIndex, layerIndex, 'delete')
                }
                variant='tertiary'
              >
                <Icon boxSize='16px' icon='pencil_subtract' />
              </Button>
            </HStack>
          </VStack>
          <SidebarDivider />
        </>
      )}

      <VStack alignItems='flex-start' px='4'>
        <SectionTitle title={t('editor.path.editPath')} icon='edit_pen' />
        {layer.isClosed ? (
          <Button
            w='full'
            h='8'
            fontSize='xs'
            fontWeight='normal'
            leftIcon={<Icon boxSize='16px' icon='corner_path' />}
            onClick={() => enableSmoothLayer(componentIndex, layerIndex)}
            variant='secondary'
          >
            {t('editor.path.roundCorner')}
          </Button>
        ) : (
          <Button
            w='full'
            h='8'
            fontSize='xs'
            fontWeight='normal'
            leftIcon={<Icon boxSize='16px' icon='area' />}
            onClick={() => enableSmoothLayer(componentIndex, layerIndex)} // TODO CLOSE COMMAND
            variant='secondary'
          >
            {t('general.close')}
          </Button>
        )}
      </VStack>
      {layer.editPoint != null && layer.isClosed && (
        <>
          <SidebarDivider />
          <Stack w='full' px='4'>
            <SectionTitle title={t('editor.position.title')} icon='position' />

            <EditorNumberInput
              text='X'
              initialValue={Math.round(layer.editPoint?.x)}
              onChange={(val) => {
                setPathPointPosition(
                  componentIndex,
                  layerIndex,
                  layer.editPoint!.id,
                  val,
                  layer.editPoint!.y,
                );
              }}
            />
            <EditorNumberInput
              text='Y'
              initialValue={Math.round(layer.editPoint?.y)}
              onChange={(val) => {
                setPathPointPosition(
                  componentIndex,
                  layerIndex,
                  layer.editPoint!.id,
                  layer.editPoint!.x,
                  val,
                );
              }}
            />
          </Stack>
        </>
      )}
      {layer.isClosed && (
        <>
          <SidebarDivider />
          <VisibilityToggle />
        </>
      )}
    </Stack>
  );
};
