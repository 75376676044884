import { useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { useToast } from '$/hooks/useToast';
import { logError } from '$/logger';
import {
  ModuleSendAction,
  ModuleSendSelectAction,
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { config } from '$/services/endpoints';
import {
  BrushType,
  LayerEditMode,
  MagicModeOptions,
} from '$/services/usecases/editor/mapper/editorStatus';
import { isDevMode } from '$/utils/generalUtils';

export type ModuleSendMessageAction =
  | ModuleSendAction
  | ModuleSendSelectAction
  | ModuleSendBrushShapeAction
  | ModuleSendBrushSizeAction;

type ModuleSendMessage = {
  action: ModuleSendMessageAction;
} & Record<string, unknown>;

type BrushShape = 'round' | 'square';

enum ColorCheckerShapeOptions {
  Square = 0,
  SquareWithHole = 1,
  Circle = 2,
  CircleWithHole = 3,
}

export type EditorActions = ReturnType<typeof useEditorActions>;

export const useEditorActions = () => {
  const { colorMode } = useColorMode();
  const editorModule = useEditorStore.useEditorModule();
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const setStateMaterial = useEditorStore.useSetMaterial();
  const [iframeWindow, setIframeWindow] = useState<Window | null>(null);
  const toast = useToast();

  useEffect(() => {
    setIframeWindow(editorModule?.contentWindow ?? null);
  }, [editorModule]);

  const sendMessage = (message: ModuleSendMessage) => {
    if (iframeWindow == null) {
      logError('Editor Module not found');
      return;
    }
    // This log is here on purpose while developing, so we can see what exactly has been sent to the module
    console.log('SENT MESSAGE', message);
    iframeWindow.postMessage(JSON.stringify(message), config.EDITOR_BASE_URL);

    if (
      message.action !== ModuleSendAction.GetModuleState &&
      !message.action.includes('hover') &&
      isDevMode
    ) {
      // Toast is only for testing purposes, remove this when we have more actual working comm
      toast(`Sent action to module`, 'success', JSON.stringify(message), {
        customIcon: 'arrow_right',
        customColor: 'lightblue',
      });
    }
  };

  const getModuleState = () => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.GetModuleState,
    };
    sendMessage(message);
  };

  /**
   * MESSAGES
   */

  const loadPicture = (token: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.LoadPicture,
      token,
    };
    sendMessage(message);
  };

  const saveProject = () => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SaveProject,
    };
    sendMessage(message);
  };

  const loadProject = (projectId: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.LoadProject,
      projectId,
    };
    sendMessage(message);
  };

  const selectAction = (action: ModuleSendSelectAction) => {
    const message: ModuleSendMessage = {
      action: action,
    };
    sendMessage(message);
  };

  const selectBooleanAction = (
    booleanMode: 'union' | 'substract' | 'intersect' | 'exclude',
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DoBooleanOperation,
      booleanMode,
    };
    sendMessage(message);
  };

  const createComponent = (name: string) => {
    const initialMaterial = useEditorStore.getState().initialMaterialId;

    const message: ModuleSendMessage = {
      action: ModuleSendAction.AddElement,
      name,
    };
    sendMessage(message);

    if (initialMaterial != null) {
      setMaterial(initialMaterial);
    }

    useEditorStore.setState((currentState) => ({
      components: [
        ...currentState.components,
        {
          name,
          mode: 'poly',
          autoBrightness: 0,
          brightness: 0,
          contrast: 0,
          grid: { height: 0, width: 0, x: 0, y: 0 },
          layers: [],
          materialId: initialMaterial,
          showTestPattern: false,
          height: 0,
          width: 0,
          index: currentState.components.length,
        },
      ],
    }));
  };

  const renameComponent = (componentIndex: number, newName: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RenameElement,
      name: newName,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const selectComponent = (componentIndex: number | null) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SelectElement,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const moveComponent = (oldIndex: number, newIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.MoveElement,
      data: {
        idxOld: oldIndex,
        idxNew: newIndex,
      },
    };
    sendMessage(message);
  };

  const deleteComponent = (componentIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DeleteElement,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const renameObject = (
    componentIndex: number,
    objectIndex: number,
    newName: string,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RenameLayer,
      data: {
        elementIdx: componentIndex,
        layerIdx: objectIndex,
        name: newName,
      },
    };
    sendMessage(message);
  };

  const selectObjects = (
    selectedObjects: { componentIndex: number; objectIndex: number }[],
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SelectLayer,
      data: selectedObjects.map((item) => ({
        elementIdx: item.componentIndex,
        layerIdx: item.objectIndex,
      })),
    };
    sendMessage(message);
  };

  const moveObject = (
    componentIndex: number,
    oldIndex: number,
    newIndex: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.MoveLayer,
      data: {
        elementIdx: componentIndex,
        layerIdxOld: oldIndex,
        layerIdxNew: newIndex,
      },
    };
    sendMessage(message);
  };

  const deleteObject = (componentIndex: number, objectIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DeleteLayer,
      data: {
        elementIdx: componentIndex,
        layerIdx: objectIndex,
      },
    };
    sendMessage(message);
  };

  const zoomPercentage = (percent: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ZoomPercentage,
      percent,
    };
    sendMessage(message);
  };

  const setBrushShape = (
    brushType: ModuleSendBrushShapeAction,
    brushShape: BrushShape,
  ) => {
    const message: ModuleSendMessage = {
      action: brushType,
      type: brushShape,
    };
    sendMessage(message);
  };

  const setBrushSize = (
    brushType: ModuleSendBrushSizeAction,
    brushSize: number,
  ) => {
    const message: ModuleSendMessage = {
      action: brushType,
      size: brushSize,
    };
    sendMessage(message);
  };

  const setLimitToMask = (limitToMask: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.LimitToMask,
      limit: limitToMask,
    };
    sendMessage(message);
  };

  const setMaterial = (materialId: string) => {
    if (activeComponentIndex != null) {
      const activeComponent = components[activeComponentIndex];

      if (activeComponent.showTestPattern) {
        toggleTestPattern(activeComponentIndex, false);
      }
      setStateMaterial(activeComponentIndex, materialId);
    }

    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetMaterial,
      matId: materialId,
    };

    sendMessage(message);
  };

  const setHoveredElement = (elementIndex: number | null) => {
    let message: ModuleSendMessage;
    if (elementIndex === null) {
      message = {
        action: ModuleSendAction.HoverOffElement,
      };
    } else {
      message = {
        action: ModuleSendAction.HoverElement,
        data: {
          elementIdx: elementIndex,
        },
      };
    }
    sendMessage(message);
  };

  const setHoveredLayer = (
    object: { elementIndex: number; layerIndex: number } | null,
  ) => {
    let message: ModuleSendMessage;

    if (object == null) {
      message = {
        action: ModuleSendAction.HoverOffLayer,
      };
    } else {
      message = {
        action: ModuleSendAction.HoverLayer,
        data: {
          elementIdx: object.elementIndex,
          layerIdx: object.layerIndex,
        },
      };
    }

    sendMessage(message);
  };

  const setHoveredGrid = (elementIndex: number | null) => {
    let message: ModuleSendMessage;
    if (elementIndex === null) {
      message = {
        action: ModuleSendAction.HoverOffGrid,
      };
    } else {
      message = {
        action: ModuleSendAction.HoverOnGrid,
        data: {
          elementIdx: elementIndex,
        },
      };
    }
    sendMessage(message);
  };

  const setColorChecker = (colorChecker: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ColorChecker,
      status: colorChecker ? 1 : 0,
    };
    sendMessage(message);
  };

  const setMagicWandTolerance = (tolerance: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetMagicWandTolerance,
      tolerance,
    };
    sendMessage(message);
  };

  const setMagicWandCircumference = (circumference: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetMagicWandCircumference,
      circumference,
    };
    sendMessage(message);
  };

  const moveGrid = (elementIndex: number, x: number, y: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.MoveGrid,
      data: {
        elementIdx: elementIndex,
        x,
        y,
      },
    };
    sendMessage(message);
  };

  const setRetouchPaintColor = (r: number, g: number, b: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetRetouchPaintColor,
      data: {
        r,
        g,
        b,
      },
    };
    sendMessage(message);
  };

  const setRetouchGradientColor = (
    colorIndex: number,
    r: number,
    g: number,
    b: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetRetouchGradientColor,
      data: {
        num: colorIndex,
        r,
        g,
        b,
      },
    };
    sendMessage(message);
  };

  const setRetouchPatternSize = (size: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetRetouchPatternSize,
      size,
    };
    sendMessage(message);
  };

  const setRetouchPatternType = (type: BrushType) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetRetouchPatternType,
      type,
    };
    sendMessage(message);
  };

  const setRetouchPatternMaterial = (patternMaterialIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetRetouchPatternMaterial,
      pattern: patternMaterialIndex,
    };
    sendMessage(message);
  };

  const toggleVisibility = (isVisible: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ToggleVisibility,
      visibilty: isVisible,
    };
    sendMessage(message);
  };

  const setColorCheckerSettings = (data: {
    shape?: ColorCheckerShapeOptions;
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  }) => {
    const current = useEditorStore.getState().colorChecker;
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ColorChecker,
      height: data.height ?? current.height,
      width: data.width ?? current.width,
      x: data.x ?? current.x,
      y: data.y ?? current.y,
      type: data.shape ?? current.type,
      status: current.status ? 1 : 0,
    };
    sendMessage(message);
  };

  const refreshBackgroundColor = () => {
    const isLightMode = colorMode === 'light';

    const colorValue = isLightMode ? 233 : 45;

    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetBackgroundColor,
      data: {
        r: colorValue,
        g: colorValue,
        b: colorValue,
      },
    };
    sendMessage(message);
  };

  const setElementSize = (
    elementIndex: number,
    width: number,
    height: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ResizeElement,
      data: {
        elementIdx: elementIndex,
        width,
        height,
      },
    };
    sendMessage(message);
  };

  const setLayerSize = (
    elementIndex: number,
    layerIndex: number,
    width: number,
    height: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ResizeLayer,
      data: {
        elementIdx: elementIndex,
        layerIdx: layerIndex,
        width,
        height,
      },
    };
    sendMessage(message);
  };

  const setMagicMode = (mode: MagicModeOptions) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetMagicWandMode,
      mode,
    };
    sendMessage(message);
  };

  const toggleTestPattern = (elementIndex: number, isActive: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ToggleTestPattern,
      data: {
        elementIdx: elementIndex,
        status: isActive ? 1 : 0,
      },
    };
    sendMessage(message);
  };

  const repositionElement = (elementIndex: number, x: number, y: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RepositionElement,
      data: {
        elementIdx: elementIndex,
        x,
        y,
      },
    };
    sendMessage(message);
  };

  const repositionLayer = (
    elementIndex: number,
    layerIndex: number,
    x: number,
    y: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RepositionLayer,
      data: {
        elementIdx: elementIndex,
        layerIdx: layerIndex,
        x,
        y,
      },
    };
    sendMessage(message);
  };

  const setElementSettings = (
    elementIndex: number,
    settings: {
      width?: number;
      height?: number;
      autoBrightness?: number;
      brightness?: number;
      contrast?: number;
    },
  ) => {
    const element = useEditorStore.getState().components[elementIndex];

    const message: ModuleSendMessage = {
      action: ModuleSendAction.ElementSettings,
      data: {
        elementIdx: elementIndex,
        gridWidth: settings.width ?? element.grid.width,
        gridHeight: settings.height ?? element.grid.height,
        autoBrightness: settings.autoBrightness ?? element.autoBrightness,
        brightness: settings.brightness ?? element.brightness,
        contrast: settings.contrast ?? element.contrast,
      },
    };
    sendMessage(message);
  };

  const resetGrid = () => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ResetGrid,
    };
    sendMessage(message);
  };

  const setPathPointPosition = (
    elementIndex: number,
    layerIndex: number,
    pointIndex: number,
    x: number,
    y: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ChangeLayerPointPosition,
      data: {
        elementIdx: elementIndex,
        layerIdx: layerIndex,
        layerPointIdx: pointIndex,
        x,
        y,
      },
    };
    sendMessage(message);
  };

  const setLayerEditMode = (
    elementIndex: number,
    layerIndex: number,
    editMode: LayerEditMode,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ChangeLayerEditMode,
      data: {
        elementIdx: elementIndex,
        layerIdx: layerIndex,
        editMode,
      },
    };
    sendMessage(message);
  };

  const enableSmoothLayer = (elementIndex: number, layerIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.EnableSmoothLayer,
      data: {
        elementIdx: elementIndex,
        layerIdx: layerIndex,
      },
    };
    sendMessage(message);
  };

  return {
    loadPicture,
    selectAction,
    selectBooleanAction,
    createComponent,
    renameComponent,
    selectComponent,
    moveComponent,
    deleteComponent,
    renameObject,
    selectObjects,
    moveObject,
    deleteObject,
    zoomPercentage,
    setBrushShape,
    setBrushSize,
    setLimitToMask,
    getModuleState,
    setMaterial,
    setHoveredElement,
    setHoveredLayer,
    setColorChecker,
    saveProject,
    loadProject,
    setMagicWandTolerance,
    setMagicWandCircumference,
    moveGrid,
    setRetouchPaintColor,
    setRetouchGradientColor,
    setRetouchPatternSize,
    setRetouchPatternType,
    setRetouchPatternMaterial,
    toggleVisibility,
    setColorCheckerSettings,
    refreshBackgroundColor,
    setElementSize,
    setLayerSize,
    setMagicMode,
    toggleTestPattern,
    repositionElement,
    repositionLayer,
    setElementSettings,
    setHoveredGrid,
    resetGrid,
    setPathPointPosition,
    setLayerEditMode,
    enableSmoothLayer,
  };
};
