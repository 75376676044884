import { Box, Button, HStack, Image, Spacer } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { endpointFactory } from '$/services/endpoints';
import { BrandCountry } from '$/services/usecases/admin/mapper/brandCountries';
import { Scene } from '$/services/usecases/scenes';

interface Props {
  scene: Scene;
  brandCountries: BrandCountry[];
  onEdit: (scene: Scene) => void;
}

export const InspirationItem: FC<Props> = ({
  scene,
  brandCountries,
  onEdit,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      as='li'
      w='full'
      mb='4'
      px='8'
      py='4'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
    >
      <HStack gap='6'>
        <Image
          boxSize='4.5rem'
          border='1px solid'
          borderColor='border'
          borderRadius='8px'
          objectFit='cover'
          src={endpointFactory.sceneImageUrl({ id: scene.id })}
        />
        <Box w='16rem'>
          <Box color='lighterText'>{scene.id}</Box>
          <Box fontWeight='bold'>{scene.nameKey}</Box>
        </Box>
        <Spacer />
        <Box>
          {brandCountries
            .map((brandCountry) =>
              t(`countries.${brandCountry.id}`, {
                defaultValue: brandCountry.id,
              }),
            )
            .join(', ')}
        </Box>
        <Button
          minW='auto'
          fontSize='sm'
          leftIcon={<Icon icon='edit_pen' boxSize='3' />}
          onClick={() => onEdit(scene)}
          variant='tertiary'
        >
          {t('admin.inspirations.editButton')}
        </Button>
      </HStack>
    </Box>
  );
};
