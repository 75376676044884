import {
  HStack,
  Button,
  Stack,
  Text,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  HookFormDropdownInput,
  HookFormDropdownInputProps,
} from '$/components/core/Form/HookFormDropdownInput';
import {
  HookFormSwitchProps,
  HookFormSwitch,
} from '$/components/core/Form/HookFormSwitch';
import { useCloudMessageTranslation } from '$/hooks/useCloudMessageTranslation';
import { useToast } from '$/hooks/useToast';
import { SelectedLanguagesTags } from '$/pages/AdminPages/pages/CountriesPage/components/SelectedLanguagesTags';
import { useFilteredLanguageCodes } from '$/pages/AdminPages/pages/CountriesPage/hooks/useFilteredAvailableLanguageCodes';
import { useBrandCountryStore } from '$/pages/AdminPages/pages/CountriesPage/stores/useBrandCountryStore';
import { queryClient } from '$/services/fetcher';
import {
  brandCountriesQuery,
  deleteBrandCountry,
  updateBrandCountry,
} from '$/services/usecases/admin/brandCountries';

type EditBrandCountryData = {
  languages: string[];
  languageInput: string;
  isActive: boolean;
};

const DropdownInput = (
  props: HookFormDropdownInputProps<EditBrandCountryData>,
) => <HookFormDropdownInput<EditBrandCountryData> {...props} />;

const Switch = (props: HookFormSwitchProps<EditBrandCountryData>) => (
  <HookFormSwitch<EditBrandCountryData> {...props} />
);

interface Props {
  onCloseModal: () => void;
}

export const EditBrandCountryForm: FC<Props> = ({ onCloseModal }) => {
  const { t } = useTranslation();

  const activeCountry = useBrandCountryStore.useActiveEditBrandCountry();

  const showToast = useToast();
  const { cloudMessageTranslation } = useCloudMessageTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formMethods = useForm<EditBrandCountryData>({
    defaultValues: {
      languages: activeCountry?.languageIds ?? [],
      isActive: activeCountry?.isActive,
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: updateBrandCountry,
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: brandCountriesQuery.queryKey,
      });
    },
    onSuccess: (res) => {
      if (res.isSuccessful) {
        onCloseModal();
        return;
      }

      showToast(
        t('admin.countries.addCountryBrand.generalError'),
        'error',
        cloudMessageTranslation(res.response.message),
      );
    },
  });

  const { mutate: onDeleteBrandCountry, isPending: isDeletePending } =
    useMutation({
      mutationFn: deleteBrandCountry,
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: brandCountriesQuery.queryKey,
        });
      },
      onSuccess: (res) => {
        if (res.isSuccessful) {
          onCloseModal();
          return;
        }

        showToast(
          t('admin.countries.addCountryBrand.generalError'),
          'error',
          cloudMessageTranslation(res.response.message),
        );
      },
    });

  const languages = formMethods.watch('languages');
  const query = formMethods.watch('languageInput');
  const { filteredLanguageCodes } = useFilteredLanguageCodes(query, languages);

  useEffect(() => {
    if (query?.length > 0) onOpen();
  }, [query, onOpen]);

  const onSubmit = (data: EditBrandCountryData) => {
    if (!activeCountry) return;

    mutate({
      id: activeCountry.id,
      brandCountry: {
        isActive: data.isActive,
        languageIds: data.languages,
      },
    });
  };

  const selectLanguage = (code?: string) => {
    if (!code && (!filteredLanguageCodes || !filteredLanguageCodes.length)) {
      return;
    }

    formMethods.resetField('languageInput');
    setTimeout(() => onClose(), 0);

    const newCode = code ?? filteredLanguageCodes![0];
    formMethods.setValue('languages', [
      ...(formMethods.getValues('languages') || []),
      newCode,
    ]);
  };

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' gap='6' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack gap='6'>
          <Stack gap='0'>
            <Text color='lighterText'>{activeCountry?.id}</Text>
            <Text fontSize='xl' fontWeight='bold'>
              {t(`countries.${activeCountry?.id}`, {
                defaultValue: activeCountry?.id,
              })}
            </Text>
          </Stack>

          <Stack>
            <DropdownInput
              dropdownContent={() => {
                return (
                  <Stack pt='2' pb='6px'>
                    {filteredLanguageCodes?.length === 0 && (
                      <Text px='4' py='6px' color='lighterText' fontSize='sm'>
                        {t('admin.countries.addCountryBrand.noResults')}
                      </Text>
                    )}
                    {filteredLanguageCodes?.map((code) => (
                      <Button
                        key={code}
                        px='4'
                        py='6px'
                        fontSize='sm'
                        fontWeight='normal'
                        textAlign='left'
                        _hover={{ bg: 'bodyBackground' }}
                        cursor='pointer'
                        onClick={() => {
                          selectLanguage(code);
                        }}
                        variant='unstyled'
                      >
                        <Text>{t(`languages.${code}`)}</Text>
                      </Button>
                    ))}
                  </Stack>
                );
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  selectLanguage();
                }
              }}
              dropdownOpen={isOpen}
              toggleDropdown={(open) => (open ? onOpen() : onClose())}
              accessor='languageInput'
              label={t('admin.countries.addCountryBrand.languagesLabel')}
              placeholder={t(
                'admin.countries.addCountryBrand.languagesPlaceholder',
              )}
              registerOptions={{
                validate: {
                  countryExists: () =>
                    formMethods.getValues('languages').length > 0 ||
                    t(
                      'admin.languages.addCountryBrand.errorMessages.minimumOneLanguage',
                    ),
                },
              }}
            />
            <Text color='lighterText' fontSize='xs'>
              {t(
                'admin.countries.addCountryBrand.languagesForThisBrandCountry',
              )}
            </Text>
          </Stack>

          <SelectedLanguagesTags
            languages={languages}
            onDelete={(index) =>
              formMethods.setValue(
                'languages',
                languages.filter((_, i) => i !== index),
              )
            }
          />

          <HStack justify='space-between' w='full'>
            <Text>{t('admin.countries.isActive')}</Text>
            <Box>
              <Switch accessor='isActive' />
            </Box>
          </HStack>
        </Stack>

        <HStack justifyContent='space-between' w='full' mt='4'>
          <Button
            color='lighterText'
            fontSize='sm'
            isLoading={isPending}
            type='submit'
            variant='ghost'
          >
            {t('admin.countries.editCountryBrand.saveChanges')}
          </Button>
          <Button
            px='6'
            py='3'
            fontSize='sm'
            isLoading={isDeletePending}
            onClick={() => onDeleteBrandCountry(activeCountry!.id)}
            type='button'
            variant='danger'
          >
            {t('admin.countries.editCountryBrand.deleteCountryBrand')}
          </Button>
        </HStack>
      </Stack>
    </FormProvider>
  );
};
